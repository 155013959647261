import { EventBus } from 'chimera/all/plugins/eventbus'
import { ConsentLevel } from 'chimera/all/plugins/eventbus/events'

export const handleConsentEvent = async () => {
  try {
    const consentLevelMap = {
      ALL_ACCEPTED: ConsentLevel.YES,
      SOME_ACCEPTED: ConsentLevel.PARTIALLY_YES,
      ALL_DENIED: ConsentLevel.NO,
    }

    const services = await getConsentDetails()
    const status = services?.consent?.status
    const level = consentLevelMap[status] || ConsentLevel.NO

    EventBus.emitConsentEvent({
      level,
      services,
    })
  } catch (error) {
    EventBus.emitErrorAppErrorEvent(
      new Error('Failed to handle consent event'),
      'handleConsentEvent',
    )
  }
}

/**
 * @param {string[]} requiredServiceIds
 * @returns {Promise<boolean>}
 */
export async function hasConsentForServices(requiredServiceIds) {
  const isEventTriggered = await new Promise((resolve) => {
    const timeoutId = setTimeout(() => resolve(true), 2000)

    window.addEventListener(
      'ucEvent',
      () => {
        clearTimeout(timeoutId)
        resolve(true)
      },
      { once: true },
    )
  })

  if (!isEventTriggered || !window.__ucCmp) {
    return false
  }

  try {
    const consentedServices = await getConsentDetails()
    return requiredServiceIds.every((serviceId) =>
      consentedServices?.consent?.serviceIds?.includes(serviceId),
    )
  } catch (error) {
    EventBus.emitErrorAppErrorEvent(error, 'hasConsentForServices')
    return false
  }
}

/**
 * @returns {Promise<[]>}
 */
function getConsentDetails() {
  return runUsercentricsFunction(
    'getConsentDetails',
    'Unable to retrieve consent details',
  )
}

/**
 */
export function openSecondLayer() {
  runUsercentricsFunction('showSecondLayer', 'Unable to show second layer')
}

/**
 * TODO: PD-6757 Implement this function
 */
export function showEmbeddings() {
  EventBus.emitErrorAppErrorEvent(
    new Error('ShowEmbedding not yet implemented. PD-6757'),
    {},
  )
}

/**
 * Runs a Usercentrics function and handles errors.
 * @param {string} functionName
 * @param {string} errorMessage
 * @returns {void|Promise<any>}
 */
async function runUsercentricsFunction(functionName, errorMessage) {
  const ucCmp = window.__ucCmp

  if (typeof ucCmp?.[functionName] !== 'function') {
    const error = new Error(
      `${functionName} is not a valid function on Usercentrics.`,
    )
    EventBus.emitErrorAppErrorEvent(error, functionName)
    return null
  }

  try {
    return await ucCmp[functionName]()
  } catch (error) {
    EventBus.emitErrorAppErrorEvent(error, functionName)
    return null
  }
}

/**
 * Installs the Usercentrics script.
 * @param {string} usercentricsId
 */
export function installUsercentricsScript(usercentricsId) {
  if (!usercentricsId) {
    EventBus.emitErrorAppErrorEvent(
      new Error('Usercentrics ID is not provided'),
      'installUsercentricsScript',
    )
    return
  }

  if (!document.getElementById('usercentrics-cmp')) {
    const usercentricsScript = document.createElement('script')
    usercentricsScript.id = 'usercentrics-cmp'
    usercentricsScript.src = '//web.cmp.usercentrics.eu/ui/loader.js'
    usercentricsScript.setAttribute('data-settings-id', usercentricsId)
    usercentricsScript.async = true
    document.head.appendChild(usercentricsScript)
  }
}
