import { EventBus } from 'chimera/all/plugins/eventbus'
import { ConsentLevel } from 'chimera/all/plugins/eventbus/events'
import {
  handleConsentEvent as handleConsentEventV2,
  hasConsentForServices as hasConsentForServicesV2,
  installUsercentricsScript as installUsercentricsScriptV2,
  openSecondLayer as openSecondLayerV2,
  showEmbeddings as showEmbeddingsV2,
} from 'chimera/all/plugins/config/usercentrics.v2'

import {
  handleConsentEvent as handleConsentEventV3,
  hasConsentForServices as hasConsentForServicesV3,
  installUsercentricsScript as installUsercentricsScriptV3,
  openSecondLayer as openSecondLayerV3,
  showEmbeddings as showEmbeddingsV3,
} from 'chimera/all/plugins/config/usercentrics.v3'
import { ExperimentSource } from 'chimera/all/mixins/usesExperiments'

const CONSENT_STATUS = 'consent_status'
const IS_EXPLICIT = 'explicit'

let options = {
  id: null,
}

export const ServiceIds = {
  USERCENTRICS_CMP: 'H1Vl5NidjWX',
  AB_SMARTLY: 'yDs2tC_qtLDpOR',
  DOUBLECLICK_AD: '9V8bg4D63',
  FACEBOOK_PIXEL: 'ko1w5PpFl',
  FACEBOOK_SOCIAL_PLUGIN: 'XYQZBUojc',
  GA_AUDIENCES: 'FRfzEaKQ2Fm4DR',
  GOOGLE_ADS: 'S1_9Vsuj-Q',
  GOOGLE_ANALYTICS: 'HkocEodjb7',
  GOOGLE_TAG_MANAGER: 'BJ59EidsWQ',
  GOOGLE_FONTS: 'HkPBYFofN',
  HOTJAR: 'S1kgcNo_j-m',
  HUBSPOT: 'ry0QcNodoWQ',
  LINKEDIN_ADS: 'rk-nqEj_o-m',
  LINKEDIN_INSIGHT_TAG: 'JQ2XQxIk',
  LINKEDIN_PLUGIN: 'Hkx754i_iWm',
  MICROSOFT_ADVERTISING_REMARKETING: 'dsS7z9Hv4',
  SENTRY: 'rH1vNPCFR',
}

/**
 * PD-6654: Usercentrics V3
 * https://bambelo.absmartly.com/experiments/345
 */
let isV3

export default async ({ env, store }) => {
  options = {
    id: env.cookieConfigurationId,
  }

  if (!options.id) {
    this.$eventBus.emitErrorAppErrorEvent(
      new Error('Usercentrics ID is missing'),
    )
    return
  }

  /**
   * PD-6654: Usercentrics V3
   * https://bambelo.absmartly.com/experiments/345
   */
  const experiment = await store.dispatch('experiments/get', {
    id: 'PD-6654',
    source: ExperimentSource.ABSMARTLY,
  })

  isV3 = !!(experiment && experiment.isTreatment)

  // eslint-disable-next-line no-console
  console.log('Is using Usercentrics ', isV3 ? 'V3' : 'V2')

  // Load the appropriate Usercentrics script based on the experiment
  if (isV3) {
    installUsercentricsScriptV3(options.id)
  } else {
    installUsercentricsScriptV2(options.id)
  }

  await initializeCookiebar()
}

/**
 * Initialize cookie bar based on experiment
 */
export const initializeCookiebar = () => {
  window.addEventListener('ucEvent', (event) => {
    if (event.detail.type !== IS_EXPLICIT) {
      return
    }

    switch (event.detail.action) {
      case 'onInitialPageLoad':
        if (event.detail.event === CONSENT_STATUS) {
          window.addEventListener('UC_UI_INITIALIZED', () => {
            if (isV3) {
              handleConsentEventV3()
            } else {
              handleConsentEventV2()
            }
          })
        }
        break
      case 'onAcceptAllServices':
      case 'onUpdateServices':
        if (isV3) {
          handleConsentEventV3()
        } else {
          handleConsentEventV2()
        }
        break
    }
  })
}

/**
 * Initialize plugin with consent based on version
 * @param {Function} initializationFn
 * @param {Array} requiredServiceIds
 */
export function initializePluginWithConsent(
  initializationFn,
  requiredServiceIds = [],
) {
  if (!options.id) {
    initializationFn()
    return
  }

  EventBus.onConsentEvent(async (event) => {
    const consentGiven = await (isV3
      ? hasConsentForServicesV3(requiredServiceIds)
      : hasConsentForServicesV2(requiredServiceIds))

    if (event.level === ConsentLevel.YES || consentGiven) {
      initializationFn()
    }
  })
}

/**
 * Check consent for services based on version
 * @param {string[]} requiredServiceIds
 * @returns {Promise<boolean>}
 */
export async function hasConsentForServices(requiredServiceIds) {
  if (!options.id) {
    return true
  }

  return isV3
    ? await hasConsentForServicesV3(requiredServiceIds)
    : await hasConsentForServicesV2(requiredServiceIds)
}

/**
 * Open second layer based on version
 */
export function openSecondLayer() {
  if (isV3) {
    openSecondLayerV3()
  } else {
    openSecondLayerV2()
  }
}

/**
 * Show embeddings based on version
 */
export function showEmbeddings() {
  if (isV3) {
    showEmbeddingsV3()
  } else {
    showEmbeddingsV2()
  }
}
